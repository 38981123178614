



















































































































.demo__effect .vc-compact {
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.demo__effect .vc-compact-color-item {
    margin-right: 1px;
    margin-bottom: 1px;
    width: 19px;
    height: 19px;
}
