.demo {
    &__head {
        border-bottom: 1px solid $colorDarkestBlue;
        height: 80px;
    }
    
    &__logo {
        position: relative;
        left: -4px;
        width: 35px;
        height: 35px;
    }

    &__body {
        height: calc(100% - 80px);
    }

    &__side-panel {
        width: 282px;
        border-right: 1px solid $colorDarkestBlue;
        flex-shrink: 0;
        flex-grow: 0;
        //overflow-y: scroll;
        //overflow-x: hidden;
    }

    &__main {
        position: relative;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 0;
    }

    &__image-wrap {
        display: block !important;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 50px 10px rgba(255, 255, 255, 0.06);
        user-select: none;
        overflow: hidden;

        &-inner {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            min-width: 100px;
            min-height: 100px;
        }
    }

    &__image-wrap-label {
        font-size: 11px;
        text-transform: uppercase;
        color: $colorMidBlue;
        display: block;
        position: absolute;
        top: 0;
        padding: 5px 10px;
        background: rgba(0, 0, 0, 0.7);

        &.-original {
            left: 0;
        }

        &.-adjusted {
            right: 0;
        }
    }
    
    &__image-select {
        top: 0;
        left: 0;
        z-index: 9;
    }

    &__thumb {
        width: 60px;
        border-radius: 6px;
        overflow: hidden;
        border: 2px solid $colorDarkestBlue;
        cursor: pointer;

        &.-selected {
            border-color: $colorLightBlue;
        }
    }

    &__effects-panel-group {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
        background: rgba(0, 0, 0, 0.1);
        color: $colorMidBlue;
        font-size: 11px;
        text-transform: uppercase;
    }

    &__effects-panel-legend {
        border-top: 2px solid rgba(255, 255, 255, 0.07);
        background: rgba(0, 0, 0, 0.1);
        color: $colorMidBlue;
        opacity: 0.6;
    }

    &__effect {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);

        li:first-child & {
            border-top: 1px solid rgba(255, 255, 255, 0.07);
        }

        &-toggle {
            position: relative;
            cursor: pointer;
            
            &.-disabled {
                opacity: 0.4;
                cursor: default;
            }

            &-status {
                position: absolute;
                right: 20px;
                top: 20px;
                display: block;
                width: 9px;
                height: 9px;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 5px;

                &.-active {
                    background: #1f9d55;
                    box-shadow: 0 0 10px 3px transparentize(#1f9d55, 0.65);
                }
            }
        }

        &-settings {
            display: none;
            background: rgba(0, 0, 0, 0.1);

            &.-active {
                display: block;
            }
        }

        &-label {
            color: $colorMidBlue;
            font-size: 11px;
            text-transform: capitalize;
            margin-bottom: 10px;
        }

        &-panel {
        }
        
        select {
            width: 100%;
            border: 0;
            background: $colorLightBlue;
            padding: 5px;
        }
    }
    
    &__code {
        bottom: 0;
        
        code {
            border-radius: 10px;
            max-width: 90%;
        }
    }
}
