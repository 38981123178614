.u {
    &-wrap {
        max-width: maxWidth() + (outerMargin()*2);
        padding-left: outerMargin();
        padding-right: outerMargin();
        margin-left: auto;
        margin-right: auto;

        @each $breakpoint in map-keys($breakpoints) {
            @include from($breakpoint) {
                padding-left: outerMargin($breakpoint);
                padding-right: outerMargin($breakpoint);
                max-width: maxWidth($breakpoint) + (outerMargin($breakpoint)*2);
            }
        }
    }

    &-clear {
        &:before, &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    &-placed-wrap {
        position: relative;
        height: 0;
    }

    &-placed-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    $gutterBreakpoints: $breakpoints;
    $lastGutter: gutter();

    &-gutter {
        padding-left: gutter()/2;
        padding-right: gutter()/2;

        @each $breakpoint in map-keys($breakpoints) {
            @if gutter($breakpoint)!= $lastGutter {
                $lastGutter: gutter($breakpoint);

                @include from($breakpoint) {
                    padding-left: gutter($breakpoint)/2;
                    padding-right: gutter($breakpoint)/2;
                }
            }
        }

        @each $breakpoint in map-keys($gutterBreakpoints) {
            $lastGutter: 0;
            &\@#{$breakpoint} {
                @each $breakpoint in map-keys($gutterBreakpoints) {
                    @if gutter($breakpoint)!= $lastGutter {
                        $lastGutter: gutter($breakpoint);

                        @include from($breakpoint) {
                            padding-left: gutter($breakpoint)/2;
                            padding-right: gutter($breakpoint)/2;
                        }
                    }
                }
            }
            $gutterBreakpoints: map-remove($gutterBreakpoints, ($breakpoint));
        }
    }

    $gutterBreakpoints: $breakpoints;
    $lastGutter: gutter();

    &-gutter-l {
        padding-left: gutter()/2;

        @each $breakpoint in map-keys($breakpoints) {
            @if gutter($breakpoint)!= $lastGutter {
                $lastGutter: gutter($breakpoint);

                @include from($breakpoint) {
                    padding-left: gutter($breakpoint)/2;
                }
            }
        }

        @each $breakpoint in map-keys($gutterBreakpoints) {
            $lastGutter: 0;
            &\@#{$breakpoint} {
                @each $breakpoint in map-keys($gutterBreakpoints) {
                    @if gutter($breakpoint)!= $lastGutter {
                        $lastGutter: gutter($breakpoint);

                        @include from($breakpoint) {
                            padding-left: gutter($breakpoint)/2;
                        }
                    }
                }
            }
            $gutterBreakpoints: map-remove($gutterBreakpoints, ($breakpoint));
        }
    }

    $gutterBreakpoints: $breakpoints;
    $lastGutter: gutter();

    &-gutter-r {
        padding-right: gutter()/2;

        @each $breakpoint in map-keys($breakpoints) {
            @if gutter($breakpoint)!= $lastGutter {
                $lastGutter: gutter($breakpoint);

                @include from($breakpoint) {
                    padding-right: gutter($breakpoint)/2;
                }
            }
        }

        @each $breakpoint in map-keys($gutterBreakpoints) {
            $lastGutter: 0;
            &\@#{$breakpoint} {
                @each $breakpoint in map-keys($gutterBreakpoints) {
                    @if gutter($breakpoint)!= $lastGutter {
                        $lastGutter: gutter($breakpoint);

                        @include from($breakpoint) {
                            padding-right: gutter($breakpoint)/2;
                        }
                    }
                }
            }
            $gutterBreakpoints: map-remove($gutterBreakpoints, ($breakpoint));
        }
    }
}
