/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100%;
    box-sizing: border-box;
}

html, body {
    background-color: $colorBackground;
    color: $colorText;
}

body {
    position: relative;
    overflow-x: hidden;

    font-weight: normal;
    font-size: $fontSizeBase;
    line-height: $lineHeightBase;

    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;

    // Uncomment if you are using fontface observer.
    // @include font($fontDefault);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    display: block;
    margin: 0;
    font-weight: 400;
}

p, small, li {
    display: block;
    margin: 0;
}

ul, ol {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: auto;
}

figure {
    margin: 0;
}

a, input, button {
    -ms-touch-action: none !important;
}

input {
    border-radius: 0;
}

input[type="radio"] {
    -webkit-appearance: radio;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

textarea {
    resize: none;
}

select {
    border-radius: 0;
}

/* input:-webkit-autofill { -webkit-box-shadow: 0 0 0 1000px $colorInputBackground inset; } */
input::-ms-clear {
    display: none;
}

input[type="search"], input[type="text"], textarea {
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

picture {
    display: block;
}

address {
    font-style: normal;
}

a {
    color: inherit;
    text-decoration: none;
}

b, strong {
    font-weight: normal;
}

i, em {
    font-style: normal;
}

button:not(:focus) {
    outline: 0;
}

.no-outline {
    button, a, input, label, textarea, select, option {
        outline: none !important;
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

// https://medium.com/vuejs-tips/v-cloak-45a05da28dc4
[v-cloak] {
    display: none !important;
}

[class$="debug-toolbar__title"] {
    max-width: 30px;
}

.sk-circle {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 0.6s infinite ease-in-out both;
    animation: sk-circleBounceDelay 0.6s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    animation-delay: -0.55s;
}

.sk-circle .sk-circle3:before {
    animation-delay: -0.5s;
}

.sk-circle .sk-circle4:before {
    animation-delay: -0.45s;
}

.sk-circle .sk-circle5:before {
    animation-delay: -0.4s;
}

.sk-circle .sk-circle6:before {
    animation-delay: -0.35s;
}

.sk-circle .sk-circle7:before {
    animation-delay: -0.3s;
}

.sk-circle .sk-circle8:before {
    animation-delay: -0.25s;
}

.sk-circle .sk-circle9:before {
    animation-delay: -0.2s;
}

.sk-circle .sk-circle10:before {
    animation-delay: -0.15s;
}

.sk-circle .sk-circle11:before {
    animation-delay: -0.1s;
}

.sk-circle .sk-circle12:before {
    animation-delay: -0.05s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
